import { useMemo } from 'react';
import { Menu, RefinementList } from 'react-instantsearch';

import {
  FilterHeading,
  NumericFilterWithDatePicker
} from '@/components/atomic/molecules';
import { BID_LIST_TAB_CATEGORY } from '@/config/bid';
import { BID_LIST_INDEX } from '@/config/common';
import { DEFAULT_FILTER_OPTIONS_LIMIT } from '@/config/searchKit';
import {
  getDateRangeItemsForBidList,
  transformDeliveryMode
} from '@/helpers/searchkit';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from '@/services/localStorage.service';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4 max-h-80 overflow-auto',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const DynamicFilter = ({
  filterProps: { componentProps, Component, headerProps }
}) => (
  <div>
    <FilterHeading {...{ headerProps }} />
    <Component {...componentProps} />
  </div>
);

const SearchSortFilterBidsWebOrganism = ({
  clearDateFilter,
  setClearDateFilter,
  setSelectedMenu
}) => {
  const transformDeliveryModeItemsMemoized = useMemo(transformDeliveryMode, []);

  const dynamicFilters = [
    {
      componentProps: {
        attribute: 'eventDateInSeconds',
        clearDateFilter,
        index: BID_LIST_INDEX,
        items: useMemo(() => getDateRangeItemsForBidList(), []),
        menuAttribute: 'bidListTabCategory'
      },
      Component: NumericFilterWithDatePicker,
      headerProps: {
        heading: 'Event Date',
        includedAttributes: 'eventDateInSeconds',
        onClick: () => {
          removeLocalStorageValue({
            keys: [
              LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
              LOCAL_STORAGE_KEYS.DATE_RANGE
            ]
          });
          setClearDateFilter(!clearDateFilter);
        }
      }
    },
    {
      componentProps: {
        attribute: 'plannerName',
        classNames: {
          ...classNameGroup,
          list: `${classNameGroup.list} mt-4`,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      Component: RefinementList,
      headerProps: {
        heading: 'Planner Name',
        includedAttributes: 'plannerName'
      }
    },
    {
      componentProps: {
        attribute: 'eventType',
        classNames: {
          ...classNameGroup,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: 'true'
      },
      Component: RefinementList,
      headerProps: {
        heading: 'Event Type',
        includedAttributes: 'eventType'
      }
    },
    {
      componentProps: {
        attribute: 'eventCity',
        classNames: {
          ...classNameGroup
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      Component: RefinementList,
      headerProps: {
        heading: 'City',
        includedAttributes: 'eventCity'
      }
    },
    {
      componentProps: {
        attribute: 'deliveryMode',
        classNames: {
          count: classNameGroup.count,
          list: 'flex flex-col gap-4 mt-4',
          showMore: classNameGroup.showMore
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        showMore: 'true',
        transformItems: transformDeliveryModeItemsMemoized
      },
      Component: Menu,
      headerProps: {
        heading: 'Delivery Mode',
        includedAttributes: 'deliveryMode'
      }
    }
  ];

  return (
    <div className='w-80 hidden md:block'>
      <div className='searchkit-filter-container'>
        <div className='border-t border-platinum mb-4'></div>
        <FilterHeading
          {...{
            headerProps: {
              borderStyle: 'mt-3 mb-4',
              clearLabel: 'Clear All',
              heading: 'Filters',
              headingStyle: 'text-xl',
              onClick: () => {
                removeLocalStorageValue({
                  keys: [
                    LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
                    LOCAL_STORAGE_KEYS.DATE_RANGE
                  ]
                });
                setClearDateFilter(!clearDateFilter);
                setSelectedMenu(BID_LIST_TAB_CATEGORY.ALL.value);
              }
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        {dynamicFilters.map((filterProps, index) => (
          <div key={index}>
            <DynamicFilter {...{ filterProps }} />
            <div className='border-t border-platinum mt-3 mb-4' />
          </div>
        ))}
      </div>
    </div>
  );
};

export default SearchSortFilterBidsWebOrganism;
