import { SearchBox } from 'react-instantsearch';

import {
  ScopeSearchAttributeModal,
  SortResultsModal as SortResultsModalWeb
} from '@/components/atomic/atoms';
import {
  SORT_BY_ITEMS_BID_LIST,
  SORT_BY_ITEMS_ORDER_LIST
} from '@/config/searchKit';
import { getPlaceholderTextForSearchBox } from '@/helpers/searchkit';

const sortByItemsMap = {
  bidList: SORT_BY_ITEMS_BID_LIST,
  orderList: SORT_BY_ITEMS_ORDER_LIST
};

const SearchBoxFilter = ({
  searchAttributeLabelList = [],
  searchableAttribute,
  setSearchableAttribute,
  source
}) => (
  <div className='flex gap-2 h-10 md:h-13.5 mt-4'>
    <div className='flex justify-between border border-platinum rounded-md relative w-full md:w-54.7'>
      <div>
        <SearchBox
          {...{
            classNames: {
              form: 'searchkit-searchbox before:bg-searchkit-search-box-form rounded-md h-8 md:h-12 w-full md:w-auto',
              input: 'w-84 md:w-51.5 shadow-none border-none h-9 md:h-12'
            },
            placeholder: getPlaceholderTextForSearchBox({
              searchableAttribute,
              searchAttributeLabelList
            })
          }}
        />
      </div>
      <ScopeSearchAttributeModal
        {...{
          searchableAttribute,
          searchAttributeLabelList,
          setSearchableAttribute
        }}
      />
    </div>
    <SortResultsModalWeb
      {...{
        className: 'hidden md:flex',
        items: sortByItemsMap[source]
      }}
    />
  </div>
);

export default SearchBoxFilter;
