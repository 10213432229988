import { useMemo } from 'react';
import { Menu, RefinementList } from 'react-instantsearch';

import {
  DeliveryPickupRadioGroup,
  RangeSliderRefinement
} from '@/components/atomic/atoms';
import {
  FilterHeading,
  NumericFilterWithDatePicker
} from '@/components/atomic/molecules';
import { DEFAULT_FILTER_OPTIONS_LIMIT } from '@/config/searchKit';
import {
  DATE_RANGE_ITEMS_FOR_EVENT_DATE,
  shouldShowFilter,
  transformDeliveryMode
} from '@/helpers/searchkit';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from '@/services/localStorage.service';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-4 max-h-80 overflow-auto',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const DynamicFilter = ({
  filterProps: { componentProps, Component, headerProps }
}) => (
  <div>
    <FilterHeading {...{ headerProps }} />
    <Component {...componentProps} />
  </div>
);

const SearchSortFilterOrdersWebOrganism = ({
  clearDateFilter,
  setClearDateFilter,
  pageName,
  selectedDeliveryPickupDateFilter,
  setSelectedDeliveryPickupDateFilter
}) => {
  const transformDeliveryModeItemsMemoized = useMemo(transformDeliveryMode, []);

  const dynamicFilterProps = [
    {
      componentProps: {
        attribute: 'deliveryAndPickupList',
        selectedDeliveryPickupDateFilter,
        setSelectedDeliveryPickupDateFilter
      },
      Component: DeliveryPickupRadioGroup,
      headerProps: {
        heading: 'Delivery/Pickup',
        isClearable: false
      }
    },
    {
      componentProps: {
        attribute: 'eventDateInSeconds',
        clearDateFilter,
        items: Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE)
      },
      Component: NumericFilterWithDatePicker,
      headerProps: {
        heading: 'Event Date',
        includedAttributes: 'eventDateInSeconds',
        onClick: () => {
          removeLocalStorageValue({
            keys: [
              LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
              LOCAL_STORAGE_KEYS.DATE_RANGE
            ]
          });
          setClearDateFilter(!clearDateFilter);
        }
      }
    },
    {
      componentProps: {
        attribute: 'plannerName',
        classNames: {
          ...classNameGroup,
          list: `${classNameGroup.list} mt-4`,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      Component: RefinementList,
      headerProps: {
        heading: 'Planner Name',
        includedAttributes: 'plannerName'
      }
    },
    {
      componentProps: {
        attribute: 'eventType',
        classNames: {
          ...classNameGroup,
          root: 'searchkit-search-box-event-type'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: 'true'
      },
      Component: RefinementList,
      headerProps: {
        heading: 'Event Type',
        includedAttributes: 'eventType'
      }
    },
    {
      componentProps: {
        attribute: 'eventAddressCity',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-4 mt-4'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      Component: RefinementList,
      headerProps: {
        heading: 'City',
        includedAttributes: 'eventAddressCity'
      }
    },
    {
      componentProps: {
        attribute: 'deliveryMode',
        classNames: {
          count: classNameGroup.count,
          list: 'flex flex-col gap-4 mt-4',
          label:
            'searchkit-filter-menu-label text-sm text-dim-gray font-medium cursor-pointer',
          selectedItem: 'searchkit-filter-menu-selected-item',
          showMore: classNameGroup.showMore
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        showMore: 'true',
        transformItems: transformDeliveryModeItemsMemoized
      },
      Component: Menu,
      headerProps: {
        heading: 'Delivery Mode',
        includedAttributes: 'deliveryMode'
      }
    },
    {
      componentProps: {
        attribute: 'partnerOrderTotal',
        title: 'Order Value Range'
      },
      Component: RangeSliderRefinement,
      headerProps: {
        heading: 'Order Value',
        includedAttributes: 'partnerOrderTotal'
      }
    }
  ];

  return (
    <>
      <div className='searchkit-filter-container mt-4 md:w-80'>
        <FilterHeading
          {...{
            headerProps: {
              borderStyle: 'mt-3 mb-4',
              clearLabel: 'Clear All',
              heading: 'Filters',
              headingStyle: 'text-xl',
              onClick: () => {
                removeLocalStorageValue({
                  keys: [
                    LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
                    LOCAL_STORAGE_KEYS.DATE_RANGE
                  ]
                });
                setClearDateFilter(!clearDateFilter);
              }
            }
          }}
        />
        <div className='border-t border-platinum mt-3 mb-4'></div>
        {dynamicFilterProps
          .filter((filterProps) =>
            shouldShowFilter({
              key: filterProps.componentProps.attribute,
              pageName
            })
          )
          .map((filterProps, index) => (
            <div key={index}>
              <DynamicFilter {...{ filterProps }} />
              <div className='border-t border-platinum mt-3 mb-4'></div>
            </div>
          ))}
      </div>
    </>
  );
};

export default SearchSortFilterOrdersWebOrganism;
