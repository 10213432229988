import { useMemo, useState } from 'react';
import {
  ClearRefinements,
  Menu,
  RefinementList,
  useCurrentRefinements
} from 'react-instantsearch';

import {
  DeliveryPickupRadioGroup,
  RangeSliderRefinement,
  SortResultsModal
} from '@/components/atomic/atoms';
import {
  DynamicFilterMobile,
  NumericFilterWithDatePicker
} from '@/components/atomic/molecules';
import {
  DEFAULT_FILTER_OPTIONS_LIMIT,
  SORT_BY_ITEMS_ORDER_LIST
} from '@/config/searchKit';
import {
  DATE_RANGE_ITEMS_FOR_EVENT_DATE,
  shouldShowFilter,
  shouldShowSortOptions,
  transformDeliveryMode
} from '@/helpers/searchkit';
import {
  LOCAL_STORAGE_KEYS,
  removeLocalStorageValue
} from '@/services/localStorage.service';

const classNameGroup = {
  checkbox:
    'content-searchkit-checkbox searchkit-checkbox checked:content-searchkit-checkbox-checked',
  noResults: 'searchkit-no-result-found min-h-48',
  count: 'bg-brand text-white border-none font-medium text-xs',
  labelText:
    'text-sm text-dim-gray font-medium searchkit-label-text cursor-pointer',
  list: 'flex flex-col gap-2 overflow-x-hidden overflow-y-auto w-80 min-h-48 max-h-80',
  item: 'pr-2',
  searchBox: 'searchkit-search-box',
  searchableForm: 'searchkit-search-box-form',
  selectedItem: 'searchkit-selected-item',
  showMore: 'searchkit-filter-show-more-button'
};

const getCountForAttribute = ({ attribute, currentRefinements }) => {
  const isRefinement = currentRefinements.find(
    (refinement) => refinement.attribute === attribute
  );
  return isRefinement?.refinements.length;
};

const SearchSortFilterOrdersMobileOrganism = ({
  clearDateFilter,
  pageName,
  selectedDeliveryPickupDateFilter,
  setClearDateFilter,
  setSelectedDeliveryPickupDateFilter
}) => {
  const { items: currentRefinements } = useCurrentRefinements();
  const [openFilter, setOpenFilter] = useState(null);

  const transformDeliveryModeItemsMemoized = useMemo(transformDeliveryMode, []);

  const handleToggleFilter = (attribute) => {
    setOpenFilter((prevOpenFilter) =>
      prevOpenFilter === attribute ? null : attribute
    );
  };

  const dynamicFilterList = [
    {
      attribute: 'deliveryAndPickupList',
      buttonName: 'Delivery/Pickup',
      componentProps: {
        attribute: 'deliveryAndPickupList',
        selectedDeliveryPickupDateFilter,
        setSelectedDeliveryPickupDateFilter
      },
      Component: DeliveryPickupRadioGroup,
      count: 1,
      headerProps: {
        heading: 'Delivery/Pickup',
        isClearable: false
      },
      icon: 'calendar-red.svg',
      isOpen: openFilter === 'deliveryAndPickupList',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'event-date-icon.svg',
      setOpenFilter,
      shouldShowClearButton: false,
      showCount: false
    },
    {
      attribute: 'eventDateInSeconds',
      buttonName: 'Event Date',
      componentProps: {
        clearDateFilter,
        items: Object.values(DATE_RANGE_ITEMS_FOR_EVENT_DATE),
        attribute: 'eventDateInSeconds'
      },
      count: getCountForAttribute({
        attribute: 'eventDateInSeconds',
        currentRefinements
      }),
      Component: NumericFilterWithDatePicker,
      headerProps: {
        heading: 'Event Date',
        includedAttributes: 'eventDateInSeconds'
      },
      icon: 'calendar-red.svg',
      isOpen: openFilter === 'eventDateInSeconds',
      onClearAllRefinement: () => {
        removeLocalStorageValue({
          keys: [
            LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
            LOCAL_STORAGE_KEYS.DATE_RANGE
          ]
        });
        setClearDateFilter(!clearDateFilter);
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'event-date-icon.svg',
      setOpenFilter,
      showCount: false
    },
    {
      attribute: 'eventType',
      buttonName: 'Event Type',
      Component: RefinementList,
      componentProps: {
        attribute: 'eventType',
        classNames: {
          ...classNameGroup,
          root: 'searchkit-search-box-event-type min-h-48 max-h-80 overflow-auto'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: 'true'
      },
      count: getCountForAttribute({
        attribute: 'eventType',
        currentRefinements
      }),
      icon: 'event-type.svg',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      isOpen: openFilter === 'eventType',
      onToggle: handleToggleFilter,
      selectedIcon: 'event-type-white.svg',
      setOpenFilter
    },
    {
      attribute: 'plannerName',
      buttonName: 'Planner Name',
      Component: RefinementList,
      componentProps: {
        attribute: 'plannerName',
        classNames: {
          ...classNameGroup,
          root: 'searchkit-search-box-event-type min-h-48 max-h-80 overflow-auto'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchable: true,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      count: getCountForAttribute({
        attribute: 'plannerName',
        currentRefinements
      }),
      icon: 'planner-red.svg',
      isOpen: openFilter === 'plannerName',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'planner-white.svg',
      setOpenFilter
    },
    {
      attribute: 'eventAddressCity',
      buttonName: 'Event City',
      Component: RefinementList,
      componentProps: {
        attribute: 'eventAddressCity',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-2 mt-4 overflow-x-auto min-h-48 max-h-80'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        searchablePlaceholder: 'Search',
        showMore: true
      },
      count: getCountForAttribute({
        attribute: 'eventAddressCity',
        currentRefinements
      }),
      icon: 'location-icon-with-frame.svg',
      isOpen: openFilter === 'eventAddressCity',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'location-white.svg',
      setOpenFilter
    },
    {
      attribute: 'deliveryMode',
      buttonName: 'Delivery Mode',
      Component: Menu,
      componentProps: {
        attribute: 'deliveryMode',
        classNames: {
          ...classNameGroup,
          list: 'flex flex-col gap-4'
        },
        limit: DEFAULT_FILTER_OPTIONS_LIMIT,
        showMore: 'true',
        transformItems: transformDeliveryModeItemsMemoized
      },
      count: getCountForAttribute({
        attribute: 'deliveryMode',
        currentRefinements
      }),
      icon: 'searchkit-delivery-icon.svg',
      isOpen: openFilter === 'deliveryMode',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      setOpenFilter,
      selectedIcon: 'searchkit-delivery-white-icon.svg'
    },
    {
      attribute: 'partnerOrderTotal',
      buttonName: 'Order Value',
      Component: RangeSliderRefinement,
      componentProps: {
        attribute: 'partnerOrderTotal',
        title: 'Order Value Range'
      },
      count: getCountForAttribute({
        attribute: 'partnerOrderTotal',
        currentRefinements
      }),
      icon: 'event-type.svg',
      isOpen: openFilter === 'partnerOrderTotal',
      onClearAllRefinement: () => {
        handleToggleFilter();
      },
      onToggle: handleToggleFilter,
      selectedIcon: 'event-type-white.svg',
      setOpenFilter,
      showCount: false
    }
  ];
  const isShowSortIcon = shouldShowSortOptions({ pageName });
  return (
    <div>
      <div className='flex gap-3 mt-4'>
        {isShowSortIcon && (
          <div className='pr-2'>
            <SortResultsModal
              {...{
                className: 'h-8',
                items: SORT_BY_ITEMS_ORDER_LIST
              }}
            />
          </div>
        )}
        <div
          className={`flex gap-3 overflow-auto justify-start items-center ${isShowSortIcon ? 'border-l border-platinum pl-3' : ''}`}
        >
          {dynamicFilterList
            .filter(({ attribute }) =>
              shouldShowFilter({ key: attribute, pageName })
            )
            .map((props, index) => (
              <DynamicFilterMobile
                key={index}
                filterProps={props}
              />
            ))}
          <div
            onClick={() => {
              removeLocalStorageValue({
                keys: [
                  LOCAL_STORAGE_KEYS.DATE_PICKER_VALUE,
                  LOCAL_STORAGE_KEYS.DATE_RANGE
                ]
              });
              setClearDateFilter(!clearDateFilter);
            }}
          >
            <ClearRefinements
              {...{
                classNames: {
                  root: 'searchkit-clear-all-button-container',
                  button: 'searchkit-clear-all-button-mobile text-nowrap'
                },
                translations: {
                  resetButtonText: 'Clear All'
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSortFilterOrdersMobileOrganism;
